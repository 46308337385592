export interface SeoKeyWord {
  keyword: string;
  keywordVolume: number;
  keywordDifficulty: number;
}

export interface Keywords {
  [key: string]: SeoKeyWord[];
}

export const Keywords: Keywords = {
  en: [
    {
      keyword: "bedtime stories for kids",
      keywordVolume: 27100,
      keywordDifficulty: 38,
    },
    {
      keyword: "bedtime story",
      keywordVolume: 22200,
      keywordDifficulty: 38,
    },
    {
      keyword: "bedtime stories for adults",
      keywordVolume: 9900,
      keywordDifficulty: 24,
    },
    {
      keyword: "short bedtime stories",
      keywordVolume: 8100,
      keywordDifficulty: 28,
    },
  ],
  ar: [
    {
      keyword: "حكايات قبل النوم للأطفال",
      keywordVolume: 5400,
      keywordDifficulty: 22,
    },
    {
      keyword: "قصص قبل النوم",
      keywordVolume: 4400,
      keywordDifficulty: 20,
    },
    {
      keyword: "حكايات للأطفال قبل النوم",
      keywordVolume: 2900,
      keywordDifficulty: 18,
    },
    {
      keyword: "قصص عربية للأطفال",
      keywordVolume: 2400,
      keywordDifficulty: 16,
    },
  ],
  es: [
    {
      keyword: "cuentos infantiles para dormir",
      keywordVolume: 9900,
      keywordDifficulty: 38,
    },
    {
      keyword: "cuento infantil para dormir",
      keywordVolume: 1300,
      keywordDifficulty: 37,
    },
    {
      keyword: "cuenta infantil",
      keywordVolume: 1000,
      keywordDifficulty: 34,
    },
    {
      keyword: "cuentos infantiles cortos para dormir",
      keywordVolume: 1000,
      keywordDifficulty: 39,
    },
  ],
  fr: [
    {
      keyword: "histoire pour enfant",
      keywordVolume: 6600,
      keywordDifficulty: 33,
    },
    {
      keyword: "histoires pour enfants",
      keywordVolume: 2400,
      keywordDifficulty: 43,
    },
    {
      keyword: "histoire pour enfants",
      keywordVolume: 1900,
      keywordDifficulty: 37,
    },
    {
      keyword: "histoire enfant pour dormir",
      keywordVolume: 1000,
      keywordDifficulty: 25,
    },
  ],
  de: [
    {
      keyword: "kindergeschichten",
      keywordVolume: 6600,
      keywordDifficulty: 18,
    },
    {
      keyword: "kindergeschichte",
      keywordVolume: 4400,
      keywordDifficulty: 23,
    },
    {
      keyword: "kindergeschichten zum vorlesen",
      keywordVolume: 2900,
      keywordDifficulty: 29,
    },
    {
      keyword: "vorlesen kindergeschichten",
      keywordVolume: 2400,
      keywordDifficulty: 29,
    },
  ],
  zhHanse: [
    {
      keyword: "睡前故事",
      keywordVolume: 12000,
      keywordDifficulty: 35,
    },
    {
      keyword: "儿童睡前故事",
      keywordVolume: 8700,
      keywordDifficulty: 30,
    },
    {
      keyword: "成人睡前故事",
      keywordVolume: 6200,
      keywordDifficulty: 25,
    },
    {
      keyword: "简短睡前故事",
      keywordVolume: 4000,
      keywordDifficulty: 20,
    },
  ],
  zhHant: [
    {
      keyword: "睡前故事",
      keywordVolume: 9000,
      keywordDifficulty: 28,
    },
    {
      keyword: "兒童睡前故事",
      keywordVolume: 7000,
      keywordDifficulty: 26,
    },
    {
      keyword: "成人睡前故事",
      keywordVolume: 4500,
      keywordDifficulty: 22,
    },
    {
      keyword: "簡短睡前故事",
      keywordVolume: 3500,
      keywordDifficulty: 18,
    },
  ],
  ja: [
    {
      keyword: "おやすみ前の物語",
      keywordVolume: 7500,
      keywordDifficulty: 30,
    },
    {
      keyword: "子供のためのおやすみ前の物語",
      keywordVolume: 6000,
      keywordDifficulty: 28,
    },
    {
      keyword: "大人のためのおやすみ前の物語",
      keywordVolume: 4000,
      keywordDifficulty: 24,
    },
    {
      keyword: "短いおやすみ前の物語",
      keywordVolume: 3000,
      keywordDifficulty: 20,
    },
  ],
  ko: [
    {
      keyword: "아이들을 위한 자장가 이야기",
      keywordVolume: 5300,
      keywordDifficulty: 27,
    },
    {
      keyword: "자장가 이야기",
      keywordVolume: 4600,
      keywordDifficulty: 25,
    },
    {
      keyword: "성인을 위한 자장가 이야기",
      keywordVolume: 3200,
      keywordDifficulty: 22,
    },
    {
      keyword: "짧은 자장가 이야기",
      keywordVolume: 2700,
      keywordDifficulty: 20,
    },
  ],
  ru: [
    {
      keyword: "сказки на ночь для детей",
      keywordVolume: 8100,
      keywordDifficulty: 34,
    },
    {
      keyword: "сказки на ночь",
      keywordVolume: 6900,
      keywordDifficulty: 32,
    },
    {
      keyword: "короткие сказки на ночь",
      keywordVolume: 5200,
      keywordDifficulty: 28,
    },
    {
      keyword: "сказки на ночь для взрослых",
      keywordVolume: 3500,
      keywordDifficulty: 25,
    },
  ],
  pt: [
    {
      keyword: "histórias para dormir para crianças",
      keywordVolume: 4000,
      keywordDifficulty: 29,
    },
    {
      keyword: "histórias para dormir",
      keywordVolume: 3500,
      keywordDifficulty: 27,
    },
    {
      keyword: "histórias curtas para dormir",
      keywordVolume: 2500,
      keywordDifficulty: 23,
    },
    {
      keyword: "histórias para dormir para adultos",
      keywordVolume: 2000,
      keywordDifficulty: 20,
    },
  ],
  it: [
    {
      keyword: "storie per bambini",
      keywordVolume: 27100,
      keywordDifficulty: 41,
    },
    {
      keyword: "storie per bambini per dormire",
      keywordVolume: 3600,
      keywordDifficulty: 160,
    },
    {
      keyword: "storie della buonanotte per bambini",
      keywordVolume: 2900,
      keywordDifficulty: 210,
    },
    {
      keyword: "storie per bambini da leggere",
      keywordVolume: 2900,
      keywordDifficulty: 240,
    },
  ],
  hi: [
    {
      keyword: "बच्चों के लिए सोने की कहानियाँ",
      keywordVolume: 10000,
      keywordDifficulty: 38,
    },
    {
      keyword: "सोने की कहानियाँ",
      keywordVolume: 8500,
      keywordDifficulty: 35,
    },
    {
      keyword: "बड़ों के लिए सोने की कहानियाँ",
      keywordVolume: 6000,
      keywordDifficulty: 30,
    },
    {
      keyword: "छोटी सोने की कहानियाँ",
      keywordVolume: 4500,
      keywordDifficulty: 25,
    },
  ],
  bn: [
    {
      keyword: "শিশুদের জন্য ঘুমানোর গল্প",
      keywordVolume: 5000,
      keywordDifficulty: 33,
    },
    {
      keyword: "ঘুমানোর গল্প",
      keywordVolume: 4000,
      keywordDifficulty: 30,
    },
    {
      keyword: "প্রাপ্তবয়স্কদের জন্য ঘুমানোর গল্প",
      keywordVolume: 3000,
      keywordDifficulty: 25,
    },
    {
      keyword: "সংক্ষিপ্ত ঘুমানোর গল্প",
      keywordVolume: 2000,
      keywordDifficulty: 20,
    },
  ],
};
