export interface Tone {
  name: string;
  value: string;
  description?: string;
}

export const Tones: Tone[] = [
  {
    name: "Magical",
    value: "MAG",
    description: "Enchanting and whimsical, filled with wonder and fantasy.",
  },
  {
    name: "Adventurous",
    value: "ADV",
    description:
      "Exciting and thrilling, with a focus on exploration and daring feats.",
  },
  {
    name: "Heartwarming",
    value: "HW",
    description:
      "Gentle and touching, evoking feelings of warmth and affection.",
  },
  {
    name: "Humorous",
    value: "HUM",
    description: "Light-hearted and funny, with playful and amusing elements.",
  },
  {
    name: "Mysterious",
    value: "MYS",
    description:
      "Intriguing and suspenseful, with an air of secrecy and discovery.",
  },
  {
    name: "Inspirational",
    value: "INS",
    description:
      "Uplifting and motivating, encouraging positive values and personal growth.",
  },
  {
    name: "Calm and Soothing",
    value: "CAS",
    description:
      "Relaxing and serene, designed to ease the listener into sleep.",
  },
  {
    name: "Empowering",
    value: "EMP",
    description:
      "Encouraging and confidence-boosting, focusing on personal strength and resilience.",
  },
  {
    name: "Nostalgic",
    value: "NOS",
    description:
      "Reflective and sentimental, evoking memories of the past and familiar comforts.",
  },
  {
    name: "Imaginative",
    value: "IMG",
    description:
      "Creative and vivid, stimulating the imagination with fantastical scenarios.",
  },
  {
    name: "Comforting",
    value: "COM",
    description:
      "Reassuring and gentle, providing a sense of safety and security.",
  },
  {
    name: "Exciting",
    value: "EXC",
    description:
      "Energetic and fast-paced, capturing the excitement of new experiences.",
  },
  {
    name: "Educational",
    value: "EDU",
    description:
      "Informative and enlightening, teaching valuable lessons in a fun way.",
  },
  {
    name: "Brave",
    value: "BRV",
    description:
      "Highlighting courage and bravery, inspiring the child to face fears.",
  },
  {
    name: "Dreamy",
    value: "DRM",
    description:
      "Ethereal and surreal, creating a dreamy and otherworldly atmosphere.",
  },
  {
    name: "Playful",
    value: "PLY",
    description: "Fun and cheerful, encouraging joy and laughter.",
  },
  {
    name: "Reflective",
    value: "REF",
    description:
      "Thought-provoking and contemplative, encouraging introspection.",
  },
  {
    name: "Tender",
    value: "TDR",
    description: "Soft and gentle, evoking feelings of love and care.",
  },
  {
    name: "Curious",
    value: "CUR",
    description:
      "Stimulating curiosity and wonder, encouraging the child to ask questions.",
  },
  {
    name: "Heroic",
    value: "HRO",
    description:
      "Celebrating heroism and noble deeds, inspiring admiration and emulation.",
  },
];
