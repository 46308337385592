import {
  AdultGenderEnum,
  AudioUserVoice as AudioFileUserVoice,
  ChildGenderEnum,
  Story,
  userAudioVoiceNames,
} from "src/components/StoryCreator/store/state";

import { Languages } from "src/shared/languages";
import { User } from "src/shared/user";

export interface ViewStoryInitialState {
  story: Story;
  isFetching: boolean;
  isCreatingAudio: boolean;
  storyAuthor: User | undefined;
  audioFileVoice: AudioFileUserVoice;
}

export const getViewStoryInitialState = (): ViewStoryInitialState => {
  return {
    isFetching: false,
    isCreatingAudio: false,
    storyAuthor: undefined,
    story: {
      _id: "",
      title: "",
      slug: "",
      summary: "",
      mainStory: "",
      poem: "",
      isPremium: false,
      author: "",
      createdAt: new Date(),
      audioFile: {
        url: "",
        fileName: "",
        createdAt: new Date(),
      },
      profileInfo: {
        name: "",
        gender: ChildGenderEnum.Girl || AdultGenderEnum.Female,
        age: 1,
        interests: "",
        language: Languages[0],
      },
      storyParams: {
        audioLength: 10,
        minCharacters: 3900,
        maxCharacters: 4000,
        totalCharacters: 4000,
        moral: {
          name: "",
          value: "",
        },
        tone: {
          name: "",
          value: "",
        },
        environment: {
          name: "",
          value: "",
        },
      },
      isFeatured: false,
    },
    audioFileVoice: userAudioVoiceNames[4],
  };
};
