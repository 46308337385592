export interface Moral {
  name: string;
  value: string;
  description?: string;
}

export const Morals: Moral[] = [
  {
    name: "Kindness",
    value: "KND",
    description: "Showing compassion and empathy towards others.",
  },
  {
    name: "Honesty",
    value: "HON",
    description: "The importance of telling the truth and being trustworthy.",
  },
  {
    name: "Courage",
    value: "COU",
    description: "Facing fears and standing up for what is right.",
  },
  {
    name: "Perseverance",
    value: "PER",
    description:
      "Working hard and not giving up, even when faced with challenges.",
  },
  {
    name: "Respect",
    value: "RES",
    description:
      "Valuing and treating others with consideration and appreciation.",
  },
  {
    name: "Gratitude",
    value: "GRT",
    description: "Appreciating what you have and expressing thankfulness.",
  },
  {
    name: "Generosity",
    value: "GEN",
    description: "Sharing with others and being selfless.",
  },
  {
    name: "Friendship",
    value: "FRN",
    description: "Building and maintaining positive, supportive relationships.",
  },
  {
    name: "Fairness",
    value: "FAR",
    description: "Treating others equally and making just decisions.",
  },
  {
    name: "Responsibility",
    value: "RSP",
    description: "Being accountable for one's actions and duties.",
  },
  {
    name: "Patience",
    value: "PAT",
    description:
      "Understanding the importance of waiting calmly and enduring difficulties.",
  },
  {
    name: "Humility",
    value: "HUM",
    description:
      "Being modest and acknowledging one’s limitations and the contributions of others.",
  },
  {
    name: "Forgiveness",
    value: "FRG",
    description:
      "Letting go of grudges and forgiving those who have wronged us.",
  },
  {
    name: "Creativity",
    value: "CRV",
    description:
      "Valuing imagination and original thinking to solve problems and express oneself.",
  },
  {
    name: "Teamwork",
    value: "TMW",
    description: "Collaborating and working together to achieve common goals.",
  },
  {
    name: "Cultural Appreciation",
    value: "CAP",
    description:
      "Valuing and respecting different cultures, traditions, and perspectives.",
  },
  {
    name: "Environmental Stewardship",
    value: "ENS",
    description:
      "Caring for the environment and understanding the importance of sustainability.",
  },
  {
    name: "Self-Discipline",
    value: "SDP",
    description: "Exercising control over one's actions and behaviors.",
  },
  {
    name: "Empathy",
    value: "EMP",
    description: "Understanding and sharing the feelings of others.",
  },
  {
    name: "Adaptability",
    value: "ADP",
    description:
      "Being open to change and flexible in the face of new challenges or opportunities.",
  },
];
