import {
  ProfileInfo,
  Story,
  StoryAudioFile,
  StoryParams,
  StorySeo,
} from "src/components/StoryCreator/store/state";
import {
  getCreateImagePrompt,
  getCreateStoryPrompt,
} from "src/components/StoryCreator/utils/getStoryPrompts";

import { OpenaiStore } from "./store";
import { useCreateStory } from "../features/CreateStory/useCreateStory";
import { useCreateStoryAudio } from "../features/CreateStoryAudio/useCreateStoryAudio";
import { useEffect } from "react";
import { useImageGeneration } from "../features/CreateStoryImage/useCreateStoryImage";
import { useStoryCreatorContext } from "src/components/StoryCreator/store/Provider";

export interface OpenaiManager {
  // Form
  handleUpdateChildInfo: (name: string, value: string) => void;
  // Create Story
  isCreateStoryFetching: (isFetching: boolean) => void;
  handleUpdateCreateStoryPrompt: (autoTextPrompt: string) => void;
  handleCreateStoryRequest: (
    userPrompt: string,
    profileInfo: ProfileInfo,
    storyParams: StoryParams
  ) => Promise<Story>;
  handleCreateStorySeoRequest: (
    storyId: string,
    userSeoPrompt: string
  ) => Promise<StorySeo>;
  // Create Audio
  isCreateAudioFetching: (isFetching: boolean) => void;
  handleCreateAudio: (story: Story) => Promise<StoryAudioFile | undefined>;
  // Create Image
  isCreateImageFetching: (isFetching: boolean) => void;
  handleUpdateCreateImagePrompt: (autoImagePrompt: string) => void;
  handleCreateImage: (userPrompt: string) => void;
}

export const useOpenAiGPTManager = (store: OpenaiStore): OpenaiManager => {
  const { store: storyCreatorStore } = useStoryCreatorContext();
  const {
    isCreateStoryFetching,
    handleUpdateCreateStoryPrompt,
    handleCreateStoryRequest,
    handleCreateStorySeoRequest,
  } = useCreateStory(store);

  const { isCreateAudioFetching, handleCreateAudio } =
    useCreateStoryAudio(store);

  const {
    isCreateImageFetching,
    handleUpdateCreateImagePrompt,
    handleCreateImage,
  } = useImageGeneration(store);

  const handleUpdateChildInfo = (name: string, value: string) => {
    const newProfileInfo = {
      ...store.state.profileInfo,
      [name as string]: value as string,
    };

    store.updateState("profileInfo", newProfileInfo);
  };

  useEffect(() => {
    store.updateState("profileInfo", storyCreatorStore.state.profileInfo);

    store.updateState("createStory", {
      ...store.state.createStory,
      createStoryPrompt: getCreateStoryPrompt(storyCreatorStore.state),
    });

    store.updateState("createImage", {
      ...store.state.createImage,
      createImagePrompt: getCreateImagePrompt(
        storyCreatorStore.state.profileInfo
      ),
    });
  }, [storyCreatorStore.state]);

  return {
    // Form
    handleUpdateChildInfo,
    // Create Story
    isCreateStoryFetching,
    handleCreateStoryRequest,
    handleUpdateCreateStoryPrompt,
    handleCreateStorySeoRequest,
    // Create Audio
    isCreateAudioFetching,
    handleCreateAudio,
    // Create Image
    isCreateImageFetching,
    handleUpdateCreateImagePrompt,
    handleCreateImage,
  };
};
